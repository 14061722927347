.ecosystem-section {
  position: relative;
  color: white;
  padding: 2rem;
  text-align: center;
  min-height: 80vh;
  overflow: hidden;
  margin-top: -2rem;
}

/* Twinkling Stars Animation */
@keyframes twinkle {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.star {
  position: absolute;
  background-color: white;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  animation: twinkle 3s infinite alternate;
}

/* Generate More Stars */
.ecosystem-section .star:nth-child(odd) {
  animation-duration: 2s;
}

.ecosystem-section .star:nth-child(even) {
  animation-duration: 3s;
}

.ecosystem-section .star:nth-child(3n) {
  animation-duration: 1.5s;
}

.ecosystem-header {
  margin-bottom: 2rem;
}

.ecosystem-header h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(120deg, #fff, #88a0ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(136, 160, 255, 0.5);
}

.ecosystem-subtitle {
  color: #a0a0a0;
  margin-bottom: 3rem;
  font-size: 1.2rem;
}

.ecosystem-partners {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 4rem;
  flex-wrap: wrap;
}

.partner-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 15px;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 30px rgba(136, 160, 255, 0.1);
}

.partner-logo {
  max-width: 150px;
  max-height: 60px;
  object-fit: contain;
}

.cta-section {
  margin-top: 4rem;
}

.cta-section h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #fff;
}

.cta-section p {
  color: #a0a0a0;
  margin-bottom: 2rem;
}

.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  background: linear-gradient(45deg, #4a6cf7, #88a0ff);
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px rgba(74, 108, 247, 0.4);
}

.cta-button:hover {
  background: linear-gradient(45deg, #88a0ff, #4a6cf7);
  box-shadow: 0 0 30px rgba(74, 108, 247, 0.6);
}

@media (max-width: 768px) {
  .ecosystem-partners {
    gap: 1.5rem;
  }

  .partner-card {
    width: 150px;
    height: 80px;
  }

  .partner-logo {
    max-width: 120px;
  }

  .ecosystem-header h2 {
    font-size: 2rem;
  }

  .cta-section h3 {
    font-size: 1.8rem;
  }
}
