/* Global styles */
body,
#root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.app {
  position: relative;
  background: radial-gradient(
    circle at top,
    #0a1e42,
    #010a1e
  ); /* Deep blue space effect */
  color: white;
  min-height: 100vh;
  overflow: hidden;
}

/* Background Stars Effect */
.app::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("https://www.transparenttextures.com/patterns/stardust.png");
  opacity: 0.3;
  z-index: 0;
  pointer-events: none;
}

/* Twinkling Stars Animation */
@keyframes twinkle {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.star {
  position: fixed;
  background-color: white;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  animation: twinkle 3s infinite alternate;
  z-index: 1;
  pointer-events: none;
}

/* Generate More Stars */
.star:nth-child(odd) {
  animation-duration: 2s;
}

.star:nth-child(even) {
  animation-duration: 3s;
}

.star:nth-child(3n) {
  animation-duration: 1.5s;
}

/* Ensure all content appears above background */
.main-content {
  position: relative;
  z-index: 2;
}

/* Saturn Rotation Animation */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

.title {
  font-size: 2.5em;
  margin: 0;
}

.description {
  font-size: 1.2em;
}

.tagline {
  font-style: italic;
  margin: 10px 0;
}

.info {
  color: #bbb;
}
