.features-section {
  position: relative;
  color: white;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
  overflow: hidden;
  margin-top: -5rem;
}

/* Twinkling Stars Animation */
@keyframes twinkle {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.star {
  position: absolute;
  background-color: white;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  animation: twinkle 3s infinite alternate;
}

/* Generate More Stars */
.features-section .star:nth-child(odd) {
  animation-duration: 2s;
}

.features-section .star:nth-child(even) {
  animation-duration: 3s;
}

.features-section .star:nth-child(3n) {
  animation-duration: 1.5s;
}

.features-header {
  text-align: center;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 800px;
}

.features-header h2 {
  font-size: 2.5rem;
  margin-bottom: 0.25rem;
  background: linear-gradient(120deg, #fff, #88a0ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(136, 160, 255, 0.5);
}

.features-subtitle {
  font-size: 1.2rem;
  color: #a0a0a0;
  margin-top: 0.25rem;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 0 1rem;
}

.feature-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 1.25rem;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.feature-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 0 30px rgba(136, 160, 255, 0.1);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.feature-card p {
  color: #a0a0a0;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .features-container {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }

  .features-header h2 {
    font-size: 2rem;
  }
}
