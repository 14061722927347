/* Hero Section */
.hero {
  position: relative;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}

/* Fixed Background that covers everything after hero */
.hero::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at bottom, #0a1e42, #010a1e);
  z-index: -2;
}

/* Stars texture overlay */
.hero::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("https://www.transparenttextures.com/patterns/stardust.png");
  opacity: 0.3;
  z-index: -1;
  pointer-events: none;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem;
}

/* Left Side: Text */
.hero-text {
  max-width: 600px;
  text-align: left;
  animation: fadeInLeft 1s ease-out;
}

.hero h1 {
  font-size: 6rem;
  margin-bottom: 15px;
  background: linear-gradient(120deg, #4fc3f7, #0056ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(79, 195, 247, 0.5);
  font-weight: 800;
  letter-spacing: 2px;
  animation: glow 2s ease-in-out infinite alternate;
}

.hero-subtitle {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #80d8ff;
  font-weight: 600;
  letter-spacing: 1px;
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards 0.3s;
}

.hero-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #b3e5fc;
  line-height: 1.8;
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards 0.6s;
}

.hero-cta {
  display: inline-block;
  text-decoration: none !important;
  padding: 15px 40px;
  background: linear-gradient(45deg, #0056ff, #4fc3f7);
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(79, 195, 247, 0.4);
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards 0.9s;
  position: relative;
  overflow: hidden;
}

.hero-cta::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: 0.5s;
}

.hero-cta:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(79, 195, 247, 0.6);
  background: linear-gradient(45deg, #4fc3f7, #0056ff);
}

.hero-cta:hover::before {
  left: 100%;
}

/* Right Side: Saturn Animation */
.hero-animation {
  position: relative;
  width: 400px;
  height: 400px;
  animation: floatAnimation 6s ease-in-out infinite;
  opacity: 0;
  animation: fadeInRight 1s ease-out forwards 0.3s,
    floatAnimation 6s ease-in-out infinite;
}

.saturn-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 0 20px rgba(79, 195, 247, 0.5));
  transition: transform 0.3s ease;
}

/* Animations */
@keyframes glow {
  from {
    text-shadow: 0 0 20px rgba(79, 195, 247, 0.5);
  }
  to {
    text-shadow: 0 0 30px rgba(79, 195, 247, 0.8),
      0 0 40px rgba(79, 195, 247, 0.3);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes floatAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hero-content {
    padding: 0 1rem;
  }

  .hero h1 {
    font-size: 5rem;
  }

  .hero-subtitle {
    font-size: 1.8rem;
  }

  .hero-animation {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
    padding: 2rem 1rem;
  }

  .hero-text {
    margin-bottom: 3rem;
  }

  .hero h1 {
    font-size: 4rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
  }

  .hero-animation {
    width: 250px;
    height: 250px;
  }

  .trading-features {
    justify-content: center;
  }
}

/* Add new styles for trading features */
.trading-features {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards 0.4s;
}

.feature-tag {
  background: rgba(79, 195, 247, 0.1);
  border: 1px solid rgba(79, 195, 247, 0.3);
  padding: 8px 16px;
  border-radius: 20px;
  color: #4fc3f7;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.feature-tag:hover {
  background: rgba(79, 195, 247, 0.2);
  transform: translateY(-2px);
}

.feature-highlight {
  background: linear-gradient(45deg, #0056ff, #4fc3f7);
  padding: 8px 16px;
  border-radius: 20px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 0 15px rgba(79, 195, 247, 0.3);
}

/* Update Saturn logo animation */
.hero-animation:hover .saturn-logo {
  transform: scale(1.05);
}
